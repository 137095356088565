<template>
  <v-card width="50vw" class="mx-auto" height="60vh">
    <v-card-text>
      <v-row align="center" justify="center" class="text-center">
        <v-col>
          <v-img
            :aspect-ratio="16 / 9"
            contain
            src="../../assets/Allec-New-Logo-A.png"
            width="450"
            class="mx-auto"
          ></v-img>
        </v-col>
        <v-col cols="12" v-if="currentUserType.key == 'master_admin'">
          <v-btn
            @click="clearTenant"
            class="rounded-lg"
            x-large
            color="secondary"
            dark
            width="60%"
          >
            New tenant
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-btn
            to="/supplier/manage"
            class="rounded-lg"
            x-large
            color="secondary"
            dark
            width="60%"
          >
            Supplier configuration
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-btn
            @click="goToTenantDetails"
            class="rounded-lg"
            x-large
            color="secondary"
            dark
            width="60%"
          >
            {{ manageTenantBtnText }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row justify="center">
        <accordion-component></accordion-component>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AccordionComponent from "@/components/user/AccordionManageAccount";
export default {
  components: {
    AccordionComponent,
  },
  computed: {
    ...mapGetters(["currentUserType", "currentTenant"]),
    manageTenantBtnText() {
      const self = this;
      let textTemp = "";
      if (self.currentUserType.key == "master_admin") {
        textTemp = self.$t("tenants.viewEditTenants");
      } else {
        textTemp = self.$t("tenants.configureMyTenant");
      }
      return textTemp;
    },
  },
  methods: {
    ...mapActions([
      "clearTenantForm",
    ]),
    goToTenantDetails() {
      const self = this;
      const tenantId = self.currentTenant ? self.currentTenant.id : null;
      if (self.currentUserType.key == "master_admin") {
        self.$router.push({ path: `/tenant/list` });
      } else {
        if (tenantId) {
          self.$router.push({ path: `/tenant/manage/${tenantId}` });
        }
      }
    },
    clearTenant() {
      const self = this;

      self.clearTenantForm();
      self.$router.push({ path: `/tenant/manage` });
    }
  },
};
</script>

<style>
</style>